import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import { handleLoadingError } from '~/utils/handleLoadingError';

interface INextOrderDatesStatus {
  nextOrderStatus: boolean;
  secondOrderStatus: boolean;
  thirdOrderStatus: boolean;
}

export function useProductSubscriptionPause(
  subscription: ProductSubscriptionItem,
) {
  const dialogStore = useDialogStore();
  const status = subscription.orderDates;
  const nextOrderStatus = ref(status.nextOrderDate.status);
  const secondOrderStatus = ref(status.secondOrderDate.status);
  const thirdOrderStatus = ref(status.thirdOrderDate.status);
  const nextOrderDate = ref(
    formateDateToLocaleString(status.nextOrderDate.date),
  );
  const secondOrderDate = ref(
    formateDateToLocaleString(status.secondOrderDate.date),
  );
  const thirdOrderDate = ref(
    formateDateToLocaleString(status.thirdOrderDate.date),
  );

  async function onSubmit(nextOrderDateStatus: INextOrderDatesStatus) {
    try {
      const site = useSiteIdent();
      const response = await useSecureSessionPost<string>(
        `/api/${site}/shop/product/productSubscription/update/${subscription.id}`,
        {
          interval: subscription.interval,
          status: nextOrderDateStatus,
        },
      );
      if (response) {
        dialogStore.closeDialog(DialogResponses.SUCCESS);
      } else {
        dialogStore.closeDialog(DialogResponses.CANCEL);
      }
    } catch (e: any) {
      dialogStore.closeDialog(DialogResponses.CANCEL);
      handleLoadingError(e);
    }
  }

  return {
    nextOrderStatus,
    nextOrderDate,
    secondOrderStatus,
    secondOrderDate,
    thirdOrderStatus,
    thirdOrderDate,
    onSubmit,
  };
}

function formateDateToLocaleString(date: string) {
  const deliveryDate = new Date(date);
  return {
    weekDayShort: deliveryDate.toLocaleDateString('de-DE', {
      weekday: 'short',
    }),
    weekDay: deliveryDate.toLocaleDateString('de-DE', {
      weekday: 'long',
    }),
    date: deliveryDate.toLocaleDateString('de-DE', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }),
  };
}
