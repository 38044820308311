<template>
  <Dialog>
    <Form
      id="product-subscription-pause-form"
      submit-label="formFields.form.confirm"
      cancel-label="formFields.form.cancel"
      class="px-md"
      @submit="
        onSubmit({
          nextOrderStatus: nextOrderStatus,
          secondOrderStatus: secondOrderStatus,
          thirdOrderStatus: thirdOrderStatus,
        })
      "
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
    >
      <template #default>
        <div class="text-sm product-grid md:text-md">
          <div class="product-grid__infoBanner">
            <div
              v-if="data.disableFirst"
              class="flex h-full rounded-alt-md px-sm bg-status-warn-lighter"
            >
              <div class="flex items-center icon-box bg-status-warn-light">
                <FaIcon
                  icon-class="fal fa-circle-exclamation"
                  classes="text-black mx-xs !w-sm !h-sm md:!h-[20px] md:!w-[20px]"
                />
              </div>
              <div class="my-xs mx-sm">
                <Trans
                  keypath="shop.productSubscription.pause.infoText"
                  tag="span"
                >
                  <span class="font-bold">{{
                    nextOrderDate.weekDayShort
                  }}</span>
                  <span class="font-bold">{{ nextOrderDate.date }}</span>
                </Trans>
              </div>
            </div>
            <div v-else>
              <div class="font-bold text-md md:text-lg font-text mb-sm">
                {{ t('shop.productSubscription.pause.subHeadline') }}
              </div>
              <div class="mb-sm">
                {{ t('shop.productSubscription.pause.text') }}
              </div>
            </div>
          </div>
          <div class="product-grid__orderDates">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-sm">
              <div
                class="grid grid-cols-3 rounded-alt-md p-sm md:grid-cols-1"
                :class="
                  data.disableFirst
                    ? 'bg-[var(--thm-bg-base-opc50)]'
                    : nextOrderStatus
                      ? 'bg-[var(--thm-status-success-base-opc15)] text-status-success-dark'
                      : 'bg-[var(--thm-status-danger-base-opc15)]'
                "
              >
                <div class="col-span-2 md:col-span-1">
                  <div class="float-left md:float-none mr-2xs">
                    {{ nextOrderDate.weekDay }}
                  </div>
                  <div class="float-left font-bold md:float-none">
                    {{ nextOrderDate.date }}
                  </div>
                </div>
                <Toggle
                  id="nextOrder"
                  v-model:model-value="nextOrderStatus"
                  name="nextOrder"
                  class="md:py-xs"
                  :disabled="data.disableFirst"
                />
              </div>
              <div
                class="grid grid-cols-3 rounded-alt-md p-sm md:grid-cols-1"
                :class="
                  secondOrderStatus
                    ? 'bg-[var(--thm-status-success-base-opc15)] text-status-success-dark'
                    : 'bg-[var(--thm-status-danger-base-opc15)]'
                "
              >
                <div class="col-span-2 md:col-span-1">
                  <div class="float-left md:float-none mr-2xs">
                    {{ secondOrderDate.weekDay }}
                  </div>
                  <div class="float-left font-bold md:float-none">
                    {{ secondOrderDate.date }}
                  </div>
                </div>
                <div class="flex items-end">
                  <Toggle
                    id="secondOrder"
                    v-model:model-value="secondOrderStatus"
                    name="secondOrder"
                    class="md:py-xs"
                  />
                </div>
              </div>
              <div
                class="grid grid-cols-3 rounded-alt-md p-sm md:grid-cols-1"
                :class="
                  thirdOrderStatus
                    ? 'bg-[var(--thm-status-success-base-opc15)] text-status-success-dark'
                    : 'bg-[var(--thm-status-danger-base-opc15)]'
                "
              >
                <div class="col-span-2 md:col-span-1">
                  <div class="float-left md:float-none mr-2xs">
                    {{ thirdOrderDate.weekDay }}
                  </div>
                  <div class="float-left font-bold md:float-none">
                    {{ thirdOrderDate.date }}
                  </div>
                </div>
                <Toggle
                  id="thirdOrder"
                  v-model:model-value="thirdOrderStatus"
                  name="thirdOrder"
                  class="md:py-xs"
                />
              </div>
            </div>

            <ContentSeparator class="relative separator-overwrite mt-lg" />
          </div>
          <div class="flex product-grid__image">
            <div class="relative w-[65px] sm:w-[150px] h-auto shrink-0">
              <ProductImage
                :image-url="data.product?.media?.cover"
                :alt-text="data.product?.media?.alt"
                class="w-[65px] h-[65px] sm:h-[120px] sm:w-[120px] object-scale-down mr-auto"
              />
            </div>
          </div>
          <div class="product-grid__productInfo">
            <div class="text-lg font-bold font-deco-alt">
              {{ data.product.name }}
            </div>
            <div>
              <span v-if="data.product.variantName">
                {{ data.product.variantName }}
                <span v-if="data.product.manufacturer"> | </span>
              </span>
              <span v-if="data.product.manufacturer">
                {{ data.product.manufacturer.name }}
              </span>
            </div>
            <div>
              <span
                >{{ t('shop.cart.productFinder.itemNumber') }}
                {{ data.product.productNumber }}</span
              >
            </div>
          </div>
          <div class="product-grid__quantity">
            <div class="md:w-[200px] flex flex-col flex-nowrap">
              {{ t('checkoutSubView.cart.articleAmount') }}
              {{ data.subscription.quantity }}
            </div>
          </div>
        </div>
      </template>
    </Form>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import type { Product } from '~/server/transformers/shop/product/types';
import { Toggle, ProductImage } from '@/complib';
import ContentSeparator from '@/components/block/seperator/contentSeparator.vue';
import FaIcon from '@/components/fa-icon.vue';
import { useProductSubscriptionPause } from './useProductSubscriptionPause';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import Trans from '~/components/Trans.vue';

const { t } = useTrans();
const dialogStore = useDialogStore();
dialogStore.setHeadline(t('shop.productSubscription.pause.title'));
const data = dialogStore.getDialogData<{
  subscription: ProductSubscriptionItem;
  product: Product;
  disableFirst: boolean;
}>();
const {
  nextOrderStatus,
  nextOrderDate,
  secondOrderStatus,
  secondOrderDate,
  thirdOrderStatus,
  thirdOrderDate,
  onSubmit,
} = useProductSubscriptionPause(data.subscription);
</script>
<style scoped lang="postcss">
.product-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'infoBanner infoBanner'
    'orderDates orderDates'
    'separator separator'
    'image productInfo'
    'image quantity';

  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}

.product-grid__infoBanner {
  grid-area: infoBanner;
}
.product-grid__orderDates {
  grid-area: orderDates;
  @apply mt-sm mb-md;
}
.product-grid__image {
  grid-area: image;
  @apply mr-sm mt-2xs;
}
.product-grid__productInfo {
  grid-area: productInfo;
  @apply pl-sm;
}
.product-grid__quantity {
  grid-area: quantity;
  @apply my-sm pl-sm;
}

.separator-overwrite :deep(.separator) {
  @apply !pb-md !px-0;
}

@media (min-width: 960px) {
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'infoBanner infoBanner'
      'orderDates orderDates'
      'separator separator'
      'image productInfo'
      'image quantity';

    grid-template-columns: 150px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .product-grid__infoBanner {
    grid-area: infoBanner;
  }
  .product-grid__orderDates {
    grid-area: orderDates;
    @apply mt-sm mb-0;
  }
  .product-grid__image {
    grid-area: image;
    @apply mr-sm mt-2xs;
  }
  .product-grid__productInfo {
    grid-area: productInfo;
  }
  .product-grid__quantity {
    grid-area: quantity;
    @apply my-sm;
  }
}
</style>
